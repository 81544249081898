body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: poppins, Helvetica, sans-serif;
  color: #244297;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  margin-top: 2rem;
}

.home h1 {
  font-family: Oswald, Helvetica, sans-serif;
  padding: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.buttons button {
  width: 100px;
  padding: .6rem 4rem;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  color: #244297;
  background-color: #FFD311;
  font-size: 16px;
  font-weight: bold;
}

/* .buttons button:first-child {
  font-size: 16px;
  font-weight: bold;
  color: #244297;
  background-color: #FFD311;
}
.buttons button:last-child {
  font-size: 16px;
  font-weight: bold;
  color: #244297;
  background-color: #FFD311;
} */

.generator,
.scanner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.generator h2,
.scanner h2 {
  margin-top: 2rem;
}

.scanner-div {
  width: 380px;
  height: 380px;
  margin-top: 1rem;
}

.scanner .back-btn {
  margin-top: -4rem;
}

.generator {
  gap: 1rem;
}

.generator-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generator-input input {
  padding: .5rem .5rem .5rem 1rem;
  border: none;
  outline: none;
  /* background-color: gray;
  color: white; */
  height: 50px;
  width: 250px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 5px black;
}

.generator-input button {
  border: none;
  outline: none;
  background-color: white;
  font-size: 1.5rem;
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 5px black;
}
